const formFields = {
    signIn: {
        username: {
            labelHidden: true,
            // placeholder: "Enter your email",
        },
    },
    signUp: {
        username: {

            placeholder: '이메일:',
            order: 1,
        },

        name: {
            // label: "Username:",
            // labelHidden: false,
            placeholder: "이름:",
            order: 3,
        },
        password: {
            // labelHidden: false,
            // label: "Password:",
            // placeholder: "암호:",

            order: 4,
        },
        confirm_password: {
            // labelHidden: false,
            // placeholder: "암호 확인:",
            order: 5,
        },
    },
    forceNewPassword: {
        password: {
            labelHidden: true,
            // placeholder: "Enter your Password:",
        },
    },
    resetPassword: {
        username: {
            labelHidden: true,
            placeholder: "이메일을 입력해주세요",
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            labelHidden: false,
            // placeholder: "Enter your Confirmation Code:",
            label: "New Label",
            isRequired: false,
        },
        confirm_password: {
            labelHidden: false,
            // placeholder: "Enter your Password Please:",
        },
    },
    setupTOTP: {
        QR: {
            totpIssuer: "test issuer",
            totpUsername: "amplify_qr_test_user",
        },
        confirmation_code: {
            labelHidden: false,
            label: "New Label",
            // placeholder: "Enter your Confirmation Code:",
            isRequired: false,
        },
    },
    confirmSignIn: {
        confirmation_code: {
            labelHidden: false,
            label: "New Label",
            // placeholder: "Enter your Confirmation Code:",
            isRequired: false,
        },
    },
};

export default formFields;