import "./App.css";
import React, { Component } from 'react';
import MainComponent from './comps/MainComponent';

import { translations } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';

import awsconfig from "./comps/auth/auth/awsconfig";

import components from "./comps/auth/theme/components";
import formFields from "./comps/auth/theme/formFields";
import Amplify, { Auth } from "aws-amplify";
import { withAuthenticator } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
console.log(awsconfig);
Amplify.configure(awsconfig);
const federated = {
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
};
const services = {
  async handleSignUp(formData) {
    let { username, password, attributes } = formData;
    // custom username
    username = username.toLowerCase();
    attributes.email = username
    return Auth.signUp({
      username: username,
      password: password,
      attributes: attributes
    });
  },
};

I18n.putVocabularies(translations);
I18n.putVocabularies({
  kr: {
    'Sign Up with Google': 'Google 회원가입',
  },
});
I18n.setLanguage('kr');



class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data:
      {

      }
    }
  }
  componentDidMount() {
    // fetch('http://jsonplaceholder.typicode.com/users')
    // .then(res => res.json())
    // .then((datav) => {
    //   const {data}= this.state;
    //   this.setState({ data: data.contact.merge(datav) })
    //   console.log(this.state.contacts)
    // })
    // .catch(console.log)
  }

  render() {

    return (
      <MainComponent></MainComponent>
      //   <>
      //   <AuthView />
      // </>
    );
  }
}

export default withAuthenticator(App, {
  socialProviders: ["google"],
  federated: federated,
  services: services,
  formFields: formFields,
  components: components
});