import React, { Component, Fragment } from 'react';

import Immutable from 'immutable';

import { Map, List, Record } from 'immutable';

import HeaderComponent from './header/HeaderComponent'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"


import JobListPage from './pages/JobListPage'
import UploadPage from './pages/UploadPage'
class MainComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: Map({
                contact: List()
            })


        }
    }
    componentDidMount() {

    }

    render() {

        return (
            <Fragment>
                <Router>

                    <div>
                        <Switch>
                            {/* <Route exact path="/" component={QuickSightPage}></Route> */}
                            <Route exact path="/" component={UploadPage}></Route>
                            <Route exact path="/joblist" component={JobListPage}></Route>
                            <Route exact path="/upload" component={UploadPage}></Route>
                        </Switch>
                    </div>
                </Router>
            </Fragment>
        );
    }
}

export default MainComponent;
