import React, { Component, Fragment } from 'react';
import Immutable from 'immutable';
import { Map, List, Record } from 'immutable';
import Files from "react-butterfiles";
import axios from 'axios';
import {
    Table,
    Container, Row, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup, Label, Input
} from 'reactstrap';
import moment from 'moment';
import HeaderComponent from '../header/HeaderComponent'
import { withAuthenticator } from '@aws-amplify/ui-react';
import 'datatables.net';
function statusChange(type) {
    const { data } = this.state;
    this.setState(
        { data: data.set("currentSlamState", type) }
    );

}


class UploadPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: Map({
                currentSpeed: 0,
                currentSelectedRobotId: "",
                files: [],
                errors: [],
                user_id: "spark",
                password: "abcd",
                ycor: "",
                robotList: List(),
                tableData: [],
                comment: "",
            })


        }
        statusChange = statusChange.bind(this);
        this.mqttClient = "";
        this.test = React.createRef()
    }
    readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsArrayBuffer(file);
        })
    }


    handleSubmit = async () => {
        const { data } = this.state;
        const s3SignedUrlData = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_PATH}/s3/signedurl`,
            headers: {
                Authorization: `${this.props.user.signInUserSession.idToken.jwtToken}`,
            },
            params: {
                size: data.get("files")[0].size,
                filename: data.get("files")[0].name,

            }
        });
        console.log(s3SignedUrlData)
        const urls = s3SignedUrlData.data.data.url;
        const seq = s3SignedUrlData.data.data.request_seq;
        const uploadId = s3SignedUrlData.data.data.upload_id;
        const uploadUnit = s3SignedUrlData.data.data.upload_size_unit_in_mb;
        const myHeaders = new Headers({ 'Content-Type': 'image/*' });
        console.log(data.get("files")[0]);


        let resultParts = await this.uploadParts(await this.readFileAsync(data.get("files")[0].src.file), urls, uploadUnit)

        await axios({
            method: 'Post',
            url: `${process.env.REACT_APP_API_PATH}/s3/signedurl/upload`,
            headers: {
                Authorization: `${this.props.user.signInUserSession.idToken.jwtToken}`,
            },
            data: {
                result_parts: JSON.stringify(resultParts),
                seq: seq,
                upload_id: uploadId
            }
        });
        alert("업로드 완료")
        // console.log({
        //     result_parts: JSON.stringify(resultParts),
        //     seq: seq,
        //     upload_id: uploadId
        // })

    }


    uploadParts = async (file, urls, uploadUnit) => {
        const FILE_CHUNK_SIZE = uploadUnit * 1024 * 1024;
        const _axios = axios.create()
        delete _axios.defaults.headers.put['Content-Type']
        const keys = Object.keys(urls)
        const promises = []
        for (const indexStr of keys) {
            const index = parseInt(indexStr)
            const start = index * FILE_CHUNK_SIZE
            const end = (index + 1) * FILE_CHUNK_SIZE
            const blob = index < keys.length
                ? file.slice(start, end)
                : file.slice(start)
            promises.push(_axios.put(urls[index], blob))
            axios({
                method: 'PUT',
                url: urls[index],
                headers: {
                    "Access-Control-Expose-Headers": "ETag"
                },
                data: blob
            });

        }
        const resParts = await Promise.all(promises)
        const resultParts = resParts.map((part, index) => ({
            ETag: part.headers.etag,
            PartNumber: index + 1
        }))
        return resultParts;
    }

    pushProfilePhotoToS3 = async (presignedUrl, uploadPhoto) => {
        const myHeaders = new Headers({ 'Content-Type': 'image/*' });
        const response = await fetch(presignedUrl, {
            method: 'PUT',
            headers: myHeaders,
            body: this.file
        });
    }
    onValueChanged = (name, value) => {
        const { data } = this.state;

        // this.setState(
        //    { data:data.set(event.target.name,event.target.value)}
        // )
        this.setState(
            { data: data.set(name, value) }
        )

    }
    async componentDidMount() {


    }
    render() {


        const { data } = this.state;


        //<TableBox options={options} sizeClass={"col-md-6"}></TableBox>
        return (
            <Fragment>
                <HeaderComponent></HeaderComponent>
                <div className="main_contents">
                    <Container fluid>
                        <Row>
                            <div className="col-md-12" id="time_c1">
                                <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                    {"업로드"}
                                </div>
                                <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                    <Files
                                        multiple={false}
                                        maxSize="5000mb"
                                        // multipleMaxSize="10mb"
                                        multipleMaxCount={3}
                                        accept={["video/mp4", "image/png", "image/jpeg"]}
                                        onSuccess={(files) => {
                                            let fileT = data.get("files");
                                            if (data.get("files").length == 0) {
                                                this.setState({ data: data.set("files", files) })
                                            }
                                            else {
                                                alert("하나의 파일만 업로드 할 수 있습니다.")
                                            }

                                        }}
                                        onError={errors => {
                                            //this.setState({ data: data.set("errors", errors) }) 
                                            alert(errors[0].type);

                                        }}
                                    >
                                        {({ browseFiles, getDropZoneProps, getLabelProps }) => (
                                            <>
                                                {/* <label {...getLabelProps()}>파일 드래그</label> */}
                                                <div {...getDropZoneProps({
                                                    style: {
                                                        width: 600,
                                                        minHeight: 200,
                                                        border: "2px lightgray dashed"
                                                    }
                                                })} />

                                                <ol>
                                                    {data.get("files").map(file => (
                                                        <li key={file.name}>{file.name}</li>
                                                    ))}
                                                    {data.get("errors").map(error => (
                                                        <li key={error.id}>
                                                            {error.file ? (
                                                                <span>
                                                                    {error.file.name} - {error.type}
                                                                </span>
                                                            ) : (
                                                                error.type
                                                            )}
                                                        </li>
                                                    ))}
                                                </ol>
                                                <button onClick={browseFiles}>Select files...</button>
                                            </>
                                        )}
                                    </Files>

                                </div>
                            </div>
                            <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <Button color="info" onClick={this.handleSubmit}>업로드</Button>
                            </div>

                        </Row>
                    </Container>
                </div>

            </Fragment >
        );
    }
}

export default withAuthenticator(UploadPage);
