export default {
    Auth: {
        region: "ap-northeast-2",
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_DOMAIN,
            scope: ['email', 'openid', 'profile'],
            // redirectSignIn: 'http://localhost:3000',
            // redirectSignOut: 'http://localhost:3000',

            redirectSignIn: process.env.REACT_APP_SIGNIN_URL,
            redirectSignOut: process.env.REACT_APP_SIGNOUT_URL,
            responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
}