import React, { Component } from 'react';
import Immutable from 'immutable';
import { Link } from 'react-router-dom'
import { Map, List, Record } from 'immutable';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import { Fragment } from 'react';

import {
    Container, NavbarText, Row, Col, Navbar, NavbarToggler, Collapse, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import logo from "../../assets/images/logo_reconlabs.png";
import moment from 'moment';


class HeaderComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: Map({

                collapsed: true
            })


        }
        this.interval = undefined;
    }
    toggleNavbar = async () => {
        const { data } = this.state;
        this.setState({
            data: data.set("collapsed", !data.get("collapsed"))
        })
    }
    componentDidMount() {
        // fetch('http://jsonplaceholder.typicode.com/users')
        //     .then(res => res.json())
        //     .then((datav) => {

        //         let orgList = List();
        //         const { data } = this.state;
        //         const tempList = List(datav);

        //         this.setState({ data: data.update('contact', contact => contact.concat(tempList)) })
        //         console.log(JSON.stringify(this.state.data.get("contact")))
        //     })
        //     .catch(console.log)

        const that = this;
        this.interval = setInterval(function () {

            const { data } = that.state;
            that.setState({ data: data.set("time", moment().format("YYYY-MM-DD HH:mm:ss")) });

        }, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        const { data } = this.state;

        const collapsed = data.get("collapsed")

        return (
            <Fragment>
                {(isBrowser) &&
                    (




                        <Navbar color="faded" light expand="md" className="navbar  navbar-expand-lg navbar-dark nav_menu">
                            <img src={logo} className="header_logo" alt="Logo" style={{ "marginRight": "10px" }} />
                            <NavbarBrand href="/">
                                PlicAR-VS
                            </NavbarBrand>
                            <Nav className="mr-auto" navbar>



                                <NavItem className="d-flex align-items-center">
                                    <NavLink className="font-weight-bold" href="/joblist">목록</NavLink>
                                </NavItem>
                                <NavItem className="d-flex align-items-center">
                                    <NavLink className="font-weight-bold" href="/onprocess">처리중</NavLink>
                                </NavItem>
                                <NavItem className="d-flex align-items-center">
                                    <NavLink className="font-weight-bold" href="/afterprocess">처리 완료</NavLink>
                                </NavItem>
                                <NavItem className="d-flex align-items-center">모든 목록
                                    <NavLink className="font-weight-bold" href="/all"></NavLink>
                                </NavItem>
                            </Nav>
                            <Nav navbar>
                                <NavItem className="d-flex align-items-center">
                                    <NavLink className="font-weight-bold" href="#" onClick={this.props.signOut}>로그 아웃</NavLink>
                                </NavItem>


                            </Nav>
                        </Navbar>
                    )}
                {
                    (isMobile) &&
                    (
                        <Navbar color="faded" light expand="md" className="navbar  navbar-expand-lg navbar-dark nav_menu">
                            <img src={logo} className="header_logo" alt="Logo" style={{ "marginRight": "10px" }} />
                            <NavbarBrand href="/" >
                                PlicAR-VS
                            </NavbarBrand>
                            <Nav className="mr-auto" navbar>
                            </Nav>
                            <NavbarToggler onClick={this.toggleNavbar} className="me-2" />
                            <Collapse isOpen={!collapsed} navbar>
                                <Nav navbar >
                                    <NavItem>
                                        <NavLink className="font-weight-bold" href="/joblist">목록</NavLink>
                                    </NavItem>

                                </Nav>
                            </Collapse>
                        </Navbar>

                    )

                }
            </Fragment>

        );
    }
}

export default HeaderComponent;
