import React, { Component, Fragment } from 'react';

import Immutable from 'immutable';

import { Map, List, Record } from 'immutable';


import axios from 'axios';



import { withAuthenticator } from '@aws-amplify/ui-react';

import {
    Table,
    Container, Row, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup, Label, Input
} from 'reactstrap';
import moment from 'moment-timezone';

import HeaderComponent from '../header/HeaderComponent'




import $ from 'jquery';
import 'datatables.net';




var mqttClient = require('paho-mqtt');
function statusChange(type) {
    const { data } = this.state;
    this.setState(
        { data: data.set("currentSlamState", type) }
    );

}


class JobListPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: Map({
                currentSpeed: 0,
                currentSelectedRobotId: "",
                files: [],
                errors: [],
                user_id: "spark",
                password: "abcd",
                ycor: "",
                robotList: List(),
                tableData: [],
            })


        }
        statusChange = statusChange.bind(this);
        this.mqttClient = "";
        this.test = React.createRef()
        window.that = this;


    }
    handleSubmit = async () => {
        const { data } = this.state;
        const s3SignedUrlData = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_PATH}/s3/signedurl`,
            params: {
                user_id: data.get("user_id"),
                password: data.get("password"),
                filename: data.get("files")[0].name,
            }

        });
        console.log(s3SignedUrlData)
        const url = s3SignedUrlData.data.data.url;
        const seq = s3SignedUrlData.data.data.seq;

        const myHeaders = new Headers({ 'Content-Type': 'image/*' });
        const response = await fetch(url, {
            method: 'PUT',
            headers: myHeaders,
            body: data.get("files")[0].file
        });

        // const data = await this.getS3SignUrl(this.state.file.name, this.state.file.type);
        // if (data.url) {

        //     await this.pushProfilePhotoToS3(data.url, this.state.file);
        // }
    }
    downloadFile = async (bucket, key) => {
        console.log(bucket, key);
        const s3SignedUrlData = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_PATH}/admin/signedurl`,
            params: {
                bucket: bucket,
                key: key,
            }

        });
        const url = s3SignedUrlData.data.data.url;
        console.log(url);
        window.location = url
        //        return url;
    }

    pushProfilePhotoToS3 = async (presignedUrl, uploadPhoto) => {
        const myHeaders = new Headers({ 'Content-Type': 'image/*' });
        const response = await fetch(presignedUrl, {
            method: 'PUT',
            headers: myHeaders,
            body: this.file
        });
    }
    onValueChanged = (name, value) => {
        const { data } = this.state;

        // this.setState(
        //    { data:data.set(event.target.name,event.target.value)}
        // )
        this.setState(
            { data: data.set(name, value) }
        )

    }
    async componentDidMount() {
        let that = this;
        this.oTable = $(this.test.current).DataTable({

            searching: true,
            processing: true,
            language: {
                "processing": 'Loading...'
            },
            responsive: true,
            serverSide: true,
            ajax: {
                url: `${process.env.REACT_APP_API_PATH}/jobs/all`,
                type: 'get',
            },
            columns: [
                // { "title": "idx", "data": "idx", searchable: true, "className": "dt-center", "width": "5%", "className": "dt-center" },
                // { "title": "user_id", "data": "user_id", searchable: true, "className": "dt-center", "width": "10%" },
                // { "title": "email", "data": "email", searchable: true, "className": "dt-center", "width": "5%" },

                // {
                //     "title": "유저 생성 시간", "data": "registered_date", "className": "dt-center", "width": "10%", "render": function (data, type, row, meta) {
                //         return moment(data).format("YYYY-MM-DD HH:mm:ss");
                //     }
                // },
                { "title": "idx", "data": "idx", searchable: true, "className": "dt-center", "width": "5%", "className": "dt-center" },
                { "title": "seq", "data": "seq", searchable: true, "className": "dt-center", "width": "10%" },
                { "title": "유저아이디", "data": "user_id", searchable: true, "className": "dt-center", "width": "5%" },
                { "title": "파일이름", "data": "org_file_name", searchable: true, "className": "dt-center", "width": "5%" },

                {
                    "title": "업로드 완료시간", "data": "uploaded_datetime", "className": "dt-center", "width": "10%", "render": function (data, type, row, meta) {
                        return moment(data).format("YYYY-MM-DD HH:mm:ss");
                    }
                },

                { "title": "버킷명", "data": "org_s3_bucket", "className": "dt-center", "width": "10%" },
                { "title": "키", "data": "org_s3_key", "className": "dt-center", "width": "10%" },
                { "title": "유저아이디", "data": "user_id", searchable: true, "className": "dt-center", "width": "10%" },
                {
                    "title": "다운로드", "data": "status", searchable: true, "className": "dt-center", "render": function (data, type, row, meta) {


                        return `<button class="btn btn-info"  onclick="window.that.downloadFile('${row.org_s3_bucket}','${row.org_s3_key}')" >Download</button>`;
                    }
                },

                { "title": "상태", "data": "status", "className": "dt-center", "width": "10%" },
                { "title": "backend 버전", "data": "backend_version", "className": "dt-center", "width": "10%" },
            ],

            initComplete: function () {
                $('.dataTables_filter input').unbind();
                $('.dataTables_filter input').bind('keyup', function (e) {
                    var code = e.keyCode || e.which;
                    if (code == 13) {
                        that.oTable.search(this.value).draw();
                    }
                });
            },
        });

        $(".dataTables_wrapper").css("width", "100%");

    }
    render() {


        const { data } = this.state;
        console.log(JSON.stringify(this.props.user))


        //<TableBox options={options} sizeClass={"col-md-6"}></TableBox>
        return (

            <Fragment>

                <HeaderComponent signOut={this.props.signOut}> </HeaderComponent>



                <div className="main_contents">
                    <Container fluid>
                        <Row>
                            <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                {"Job 목록"}
                            </div>
                            <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <Table bordered style={{ width: "100%" }} innerRef={this.test} />
                            </div>

                        </Row>

                    </Container>
                </div>

            </Fragment >


        );
    }
}

export default withAuthenticator(JobListPage,);
